











































.container {
  > h2 {
    font-weight: normal;
    line-height: 1;
    letter-spacing: 2px;
  }

  > h4 {
    font-weight: normal;
    line-height: 1;
    margin-top: 5px;
  }
}
@media (max-width: 767px) {
  .container {
    h2{
      font-size: 20px !important;
      line-height: 20px !important;
    }
    h4{
      font-size: 12px !important;
    }
  }
}

