
























































































































































































































































































































































































































section {
  header {
    height: 800px;
    > img {
      object-fit: cover;
    }
    > div {
      &:nth-of-type(1) {
        height: inherit;
        position: absolute;
        background-color: rgba(0, 0, 0, 0.4);
        z-index: 10;
        top: 0;
        width: 100%;

        h2 {
          text-align: center;
          font-size: 66px;
          font-family: "SY-bold";
          font-weight: 600;
          color: #fff;
          text-shadow: 0px 0px 5px rgb(98 98 98 / 32%);
          letter-spacing: 2px;
          margin-bottom: 50px;
          line-height: 2;
          margin-top: 304px;
        }

        > div {
          display: flex;
          justify-content: center;
          margin-top: 60px;

          > a {
            margin: 0 34px;
            height: 50px;
            line-height: 50px;
            border-radius: 25px;
            padding: 0 30px;
            background-color: #000096;
            display: flex;
            align-items: center;

            &:nth-child(1) {
              > i {
                width: 28px;
                height: 28px;
                margin-right: 10px;
                background: url("../assets/img/eye_white.png") no-repeat center;
              }
            }

            &:nth-child(2) {
              > i {
                width: 28px;
                height: 28px;
                margin-right: 10px;
                background: url("../assets/img/play_white.png") no-repeat center;
              }
            }

            > span {
              color: #fff;
              font-size: 18px;
            }
          }
        }
      }
    }
  }

  main {
    > .introduce {
      width: 1420px;
      margin: 0 auto;
      > ul {
        display: flex;
        justify-content: space-between;

        > li {
          padding: 6px 50px;
          text-align: center;
          line-height: 1.4;

          > div {
            &:nth-child(1) {
              white-space: nowrap;

              > i {
                color: #000096;
                font-size: 46px;
                font-family: SourceHanSansCN-Bold, sans-serif;
              }

              > span {
                color: #333;
                margin-left: 10px;
                font-size: 14px;
              }
            }

            &:nth-child(2) {
              font-size: 16px;
              width: 120px;
              color: #000;
              font-family: SourceHanSansCN-Medium, sans-serif;
            }
          }
        }
      }
    }

    > .company-profile {
      display: flex;
      justify-content: center;
      background-color: #F8F8F8;
      width: 100%;
      padding: 115px 0;

      > div {

        &:nth-child(1) {
          color: #333;
          width: 500px;

          > h3 {
            text-align: center;
            font-size: 34px;
            font-weight: bold;
            font-family: SourceHanSansCN-Medium, sans-serif;
          }

          > p {
            width: 500px;
            margin-top: 14px;
            font-size: 17px;
            line-height: 34px;
            text-indent: 2em;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 6;
          }

          > div {
            width: 155px;
            height: 45px;
            line-height: 45px;
            margin-top: 20px;
            background-color: #000096;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            > * {
              margin: 0 5px;
            }

            > i {
              width: 25px;
              height: 25px;
              border-radius: 50%;
              background: #fff url("../assets/img/more_arrow_blue.png") no-repeat center;
            }

            > span {
              color: #fff;
              font-size: 18px;
              font-family: SourceHanSansCN-Medium, sans-serif;
            }
          }
        }
        &:nth-child(2) {
          width: 700px;
          video {
            margin-left: 100px;
          }
        }
      }
    }

    > .company-culture {
      width: 1200px ;
      margin: 0 auto;
      padding: 100px 0;
      background-color: #fff;
      text-align: center;

      > div:nth-child(2) {
        > ul {
          display: flex;
          justify-content: space-between;

          > li {
            padding-top: 50px;
            color: #333;
            width: 270px;

            > i {
              width: 100px;
              height: 100px;
              display: inline-block;
              border-radius: 50%;
            }

            &:nth-child(1) {
              > i {
                background: #000096 url("../assets/img/company_sm.png") no-repeat center;
              }
            }

            &:nth-child(2) {
              > i {
                background: #000096 url("../assets/img/company_yj.png") no-repeat center;
              }
            }

            &:nth-child(3) {
              > i {
                background: #000096 url("../assets/img/company_jzg.png") no-repeat center;
              }
            }

            &:nth-child(4) {
              > i {
                background: #000096 url("../assets/img/company_js.png") no-repeat center;
              }
            }

            > h4 {
              font-size: 24px;
              font-weight: bold;
              margin: 10px 0px;
            }

            > p {
              font-size: 16px;
              font-family: SourceHanSansCN-Medium, sans-serif;
              margin: 0 13.5px;
            }
          }
        }
      }
    }

    > .development-history {
      text-align: center;
      padding: 60px 0;
      background-color: #f8f8f8;

      > div {
        &:nth-child(1) {
          width: 1200px;
          margin: 60px auto;

        }
        &:nth-child(2) {
          display: flex;
          justify-content: center;
          /* width: 1200px; */
          margin: 0 auto;
          margin-bottom: 60px;
          padding: 50px 0;

          > i {
            width: 30px;
            height: 30px;
            display: inline-block;
            position: relative;
            top: 38px;

            &:nth-of-type(1) {
              background: url("../assets/img/arrow_black_right.png") no-repeat center;
              transform: rotate(180deg);
            }

            &:nth-of-type(2) {
              background: url("../assets/img/arrow_black_right.png") no-repeat center;
            }
          }

          > ul {
            display: flex;
            justify-content: center;
            margin: 0 155px;

            > li {
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 120px;
              position: relative;

              > span {
                font-size: 16px;
                color: #000;
                text-align: center;
                position: relative;
                height: 40px;
                line-height: 40px;
              }

              > i {
                width: 30px;
                height: 30px;
                background: url("../assets/img/line_round_grey.png") no-repeat center;

                &::before {
                  content: '';
                  width: 50px;
                  height: 4px;
                  position: absolute;
                  left: -5px;
                  top: 52px;
                  background-color: #B4B4B4;
                }

                &::after {
                  content: '';
                  width: 50px;
                  height: 4px;
                  position: absolute;
                  right: -5px;
                  top: 52px;
                  background-color: #B4B4B4;
                }
              }
            }

            .current {
              > span {
                font-size: 30px;
                font-weight: bold;
                color: #000096;
              }

              > i {
                background: url("../assets/img/line_round_blue.png") no-repeat center;

                &::before {
                  background: linear-gradient(
                      to left,
                      rgba(108, 122, 252, 0.9),
                      #B4B4B4
                  );
                }

                &::after {
                  background: linear-gradient(
                      to right,
                      rgba(108, 122, 252, 0.9),
                      #B4B4B4
                  );
                }
              }
            }
          }
        }

        &:nth-child(3) {
          width: 1200px;
          border-top: solid 1px #ccc;
          margin: 0 auto;

          > ul {
            > li {
              display: flex;
              justify-content: center;
              height: 140px;

              > span {
                display: flex;
                align-items: center;
                color: #333;
                font-family: SourceHanSansCN-Medium, sans-serif;

                &:nth-child(1) {
                  flex-basis: 200px;
                  font-size: 30px;
                  justify-content: center;

                }

                &:nth-child(2) {
                  flex-basis: 1000px;
                  font-size: 20px;
                  text-align: left;
                  padding-left: 44px;
                  border-left: solid 1px #ccc;
                  border-bottom: solid 1px #ccc;
                  position: relative;

                  &:before {
                    content: "";
                    position: absolute;
                    width: 16px;
                    height: 16px;
                    background-color: #000096;
                    border-radius: 50%;
                    left: -8px;
                    top: 63px;
                  }
                }
              }
            }
          }
        }
      }
    }

    .honorary-certificate {
      text-align: center;
      padding: 60px 0;
      width: 1200px;
      margin: 0 auto;

      > div {
        &:nth-child(2) {
          padding-top: 50px;
          display: flex;
          justify-content: center;

          > div {
            &:nth-child(1) {
              flex-basis: 200px;
              border-right: solid 1px #ccc;
              position: relative;

              > ul {
                &::after {
                  content: "";
                  position: absolute;
                  width: 14px;
                  height: 14px;
                  background-color: #B4B4B4;
                  border-radius: 50%;
                  right: -7px;
                  bottom: -7px;
                }

                > li {
                  height: 140px;
                  border-top: solid 1px #ccc;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  line-height: 1;
                  cursor: pointer;

                  > i {
                    width: 50px;
                    height: 50px;
                    display: inline-block;
                  }

                  > h4 {
                    font-size: 25px;
                    font-weight: 500;
                    margin-top: 14px;
                    color: #333;
                    font-family: SourceHanSansCN-Medium, sans-serif;
                  }

                  &:nth-child(1) {
                    > i {
                      background: url("../assets/img/ryzz_qyry.svg") no-repeat center;
                      background-size: cover;
                    }
                  }

                  &:nth-child(2) {
                    > i {
                      background: url("../assets/img/ryzz_txrz.svg") no-repeat center;
                      background-size: cover;
                    }
                  }

                  &:nth-child(3) {
                    > i {
                      background: url("../assets/img/ryzz_zyzs.svg") no-repeat center;
                      background-size: cover;
                    }
                  }

                  &:nth-child(4) {
                    > i {
                      background: url("../assets/img/ryzz_zzq.svg") no-repeat center;
                      background-size: cover;
                    }
                  }

                  &:nth-child(5) {
                    > i {
                      background: url("../assets/img/ryzz_cpjc.svg") no-repeat center;
                      background-size: cover;
                    }
                  }
                }

                .current {
                  background-color: #000096;

                  > h4 {
                    color: #fff;
                  }

                  &:nth-child(1) {
                    > i {
                      background: url("../assets/img/ryzz_qyry_cur.svg") no-repeat center;
                      background-size: cover;
                    }
                  }

                  &:nth-child(2) {
                    > i {
                      background: url("../assets/img/ryzz_txrz_cur.svg") no-repeat center;

                      background-size: cover;
                    }
                  }

                  &:nth-child(3) {
                    > i {
                      background: url("../assets/img/ryzz_zyzs_cur.svg") no-repeat center;
                      background-size: cover;
                    }
                  }

                  &:nth-child(4) {
                    > i {
                      background: url("../assets/img/ryzz_zzq_cur.svg") no-repeat center;
                      background-size: cover;
                    }
                  }

                  &:nth-child(5) {
                    > i {
                      background: url("../assets/img/ryzz_cpjc_cur.svg") no-repeat center;
                      background-size: cover;
                    }
                  }

                }
              }
            }

            &:nth-child(2) {
              flex-basis: 1000px;
              margin-left: 30px;

              > ul {
                display: flex;
                justify-content: space-around;
                flex-wrap: wrap;

                > li {
                  text-align: center;
                  width: 30%;
                  margin-bottom: 30px;

                  > img {
                    max-width: 288px;
                  }

                  > p {
                    margin-top: 10px;
                    font-size: 17px;
                  }
                }
              }
            }
          }
        }

        &:nth-child(3) {
          width: 1200px;
          margin: 30px auto 0;
          display: flex;
          justify-content: center;
        }
      }
    }

    .other {
      padding: 120px 0;
      background-color: #F8F8F8;
      display: flex;
      justify-content: center;

      > div {
        margin: 0 15px;
        border: solid 1px #ccc;
        flex-basis: 370px;

        > img {
          width: 100%;
          height: 210px;
        }

        > div {
          padding: 20px 30px;

          > h4 {
            font-size: 20px;
            font-family: SourceHanSansCN-Medium, sans-serif;
          }

          > a {
            display: flex;
            align-items: center;
            margin-top: 10px;

            > i {
              width: 20px;
              height: 20px;
              display: inline-block;
              border-radius: 50%;
              vertical-align: middle;
              background: #000096 url("../assets/img/arrow-right-long.svg") no-repeat center;
              background-size: contain;
            }

            > span {
              color: rgba(98, 98, 98, 1);
              font-size: 16px;
              font-family: SourceHanSansCN-Medium, sans-serif;
              margin-left: 10px;
            }
          }
        }
      }
    }
    ::v-deep .el-dialog {
      margin: 0 !important;
      width: 80%;
      max-height: 80%;
      overflow: scroll;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
    }
    ::v-deep .el-dialog__header {
      display: none;
    }

    ::v-deep .el-dialog__body {
      padding: 60px 40px;
      display: block;
      line-height: 1.5;
      font-size: 17px;
      text-indent: 34px;
      color: #333;
      white-space: pre-line;
      word-wrap: break-word;
    }
  }
}
@media (max-width: 767px) {
  section{
    >header {
      >div:nth-of-type(1){
        >h2 {
          font-size: 32px;
          padding: 0 10px;
          margin-top: 30vh;
        }
        >div {
          flex-wrap: wrap;
          height: 110px;
          >a:nth-of-type(1){
            margin: 0 34px 20px 34px;
          }
        }
      }
    }
    >main {
      .introduce{
        display: none;
      }
      .company-profile{
        >div:nth-child(1){
          width: 100%;
          text-align: center;
          padding: 10px ;
          >h3{
            font-size: 24px;
            margin-bottom: 30px;
          }
          >p{
            width: 100%;
          }
        }
        >div:nth-child(2){
          display: none;
        }
      }
      .company-culture{
        width: 100%;
        padding: 20px 0;
        >div:nth-child(2){
          >ul{
            display: block;
            li{
              width: 100%;
            }
          }
        }
      }
      .development-history,.honorary-certificate{
        display: none;
      }
      .other{
        display: block;
        padding: 120px 15px;
      }
    }
  }
}
